import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IUserDirectoryResult } from "../../../../pages/users";
import { getRelativeTime } from "../../../../utilities/getRelativeTime";
import { WithoutAuth } from "../../../Auth/WithoutAuth";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { RouterLink } from "../../../RouterLink/RouterLink";
import { SEO } from "../../../SEO/SEO";
import { DirectoryContainer, Headline, Root } from "./UserDirectoryContent.styles";

export interface IUserDirectoryDataProps {
    readonly serverData: Array<IUserDirectoryResult>;
}

export const UserDirectoryContent: React.FC<React.PropsWithChildren<IUserDirectoryDataProps>> = ({ serverData }) => {
    const [t] = useTranslation();
    const title = t("community");
    const description = t("communityDescription");

    const userList = useMemo(
        () =>
            serverData.map((user) => {
                const { username, created } = user;
                return (
                    <li key={username}>
                        <RouterLink href={CoderOneRoute.ProfileRoute(username)}>
                            <>
                                {username} - {getRelativeTime(t, created)}
                            </>
                        </RouterLink>
                    </li>
                );
            }),
        [serverData]
    );
    const Fallback = (
        <AuthenticatedFrame title={title}>
            <ul>{userList}</ul>
        </AuthenticatedFrame>
    );

    return (
        <React.Fragment>
            <SEO title={title} description={description} />
            <WithoutAuth fallback={Fallback}>
                <NavigationHeader />
                <Root>
                    <DirectoryContainer>
                        <Headline>{t("community")}</Headline>
                        <ul>{userList}</ul>
                    </DirectoryContainer>
                </Root>
                <Footer />
            </WithoutAuth>
        </React.Fragment>
    );
};
