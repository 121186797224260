import axios from "axios";
import { GetServerDataReturn } from "gatsby";
import React from "react";
import { IUserDirectoryDataProps, UserDirectoryContent } from "../components/PageSwitch/pages/UserDirectory/UserDirectoryContent";
import { Constants } from "../utilities/Constants";

type GetServerDataResponse = GetServerDataReturn<Array<IUserDirectoryResult>>;

export interface IUserDirectoryResult {
    readonly username: string;
    readonly created: Date;
}

const getUserDirectory = async () => {
    const uri = `${Constants.ApiRoot}/users`;
    const { data } = await axios.get<Array<IUserDirectoryResult>>(uri);
    return data;
};

const Users: React.FC<React.PropsWithChildren<IUserDirectoryDataProps>> = (props) => {
    return <UserDirectoryContent {...props} />;
};
export default Users;

export const getServerData = async (): Promise<GetServerDataResponse> => {
    const response = await getUserDirectory();
    return {
        status: 200,
        props: response,
    };
};
