import styled from "styled-components";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    background-color: ${Palette.Neutral0};
`;

export const DirectoryContainer = styled.div`
    @media screen and (max-width: ${Viewport.Large}px) {
        max-width: 800px;
    }
    padding-top: 128px;
    margin: 0 auto;
    max-width: 950px;
    padding-bottom: 16px;
`;

export const Headline = styled.h1`
    font-size: 48px;
    font-weight: 700;
    padding-bottom: 8px;
`;
